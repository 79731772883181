import React, { useEffect, useRef, useState } from 'react';
import { BlurredBackground, OverlayText, ButtonWrapper, CreateProposalContainer, HeaderWrapper, SearchWrapper, SelectedDocumentsWrapper } from './styles';
import { CreateProposalProps } from './types';
import { SubHeader } from '../Header/styles';
import Text from '../Text';
import Button from '../Button';
import Close from '../../assets/icons/x-close.svg'
import CloseActive from '../../assets/icons/Close_Active.svg'
import FileActive from '../../assets/icons/File_active.svg'
import Upload from "../../assets/icons/Upload.svg";
import Search from '../../assets/icons/Search.svg'
import DocumentWizardTable from '../DocumentWizardTable';
import COLORS from '../../designSystem/colors';
import TextInput from '../TextInput';
import { DocumentType } from '../Document';
import { UploadActions } from '../Navbar/types';
import { HiddenInput } from '../Navbar/styles';
import { TableContainer } from '../DocumentTable/styles';
import SelectComparison from '../PolicyComparisonWizard/pages/SelectComparison';
import { analyzePolicy, combineDocuments, updateCoverageLines } from '../../requests';
import { useCookies } from 'react-cookie';
import Space from '../base/Space';
import SelectName from '../PolicyComparisonWizard/pages/SelectName';
import { ProgressDisplay } from '../PolicyComparisonWizard/styles';

const CreateProposal:React.FC<CreateProposalProps> = ({ proposals = [], alreadySelected = [], close, onSubmit, handleFileChange, agencyId, successfulUploads }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
    const [selected, setSelected] = useState<string[]>([]);
    const [chosen, setChosen] = useState<DocumentType[]>([]);
    const [filteredProposals, setFilteredProposals] = useState<DocumentType[]>(proposals);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [chosenIndex, setChosenIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<string>("createProposal");
    const [displayName, setDisplayName] = useState<string | null>(null);
    const [defaultName, setDefaultName] = useState<string>("");
    const [showMyFiles, setShowMyFiles] = useState<boolean>(false);

    useEffect(() => {
        setFilteredProposals(proposals);
    }, [proposals])

    useEffect(() => {
        setDefaultName(chosen.reduce((prev, curr) => {
            const str = curr.name.replace(".pdf", "");
            const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
            return `${prev}_${capitalized}`;
        }, "").slice(1, 70) + "_Proposal");
    }, [chosen])

    useEffect(() => {
        setChosen(prev => proposals.filter(r => !!prev.find(p => r.instanceId == p.instanceId) && r.category == "policy"));
    }, [proposals])

    useEffect(() => {
        setChosen(proposals.filter(p => alreadySelected.includes(p.instanceId ?? "") && p.category == "policy"));
    }, [alreadySelected])

    const handleClose = () => {
        close();
    }

    const handleSearch = (searchTerm) => {
        const lowercasedTerm = searchTerm.toLowerCase();
        const matchingProposals = proposals.filter((proposal) =>
            proposal.name.toLowerCase().includes(lowercasedTerm)
        );
        setFilteredProposals(matchingProposals);
        //setSelected([]); // TODO need to investigate how to maintain selected when changing the search field
    }

    const handleCancel = () => {
        close();
    }

    const handleCreateProposal = (s?: string) => {
        setDisplayName(s || defaultName);
        let lineSelection = false;
        for (let i = 0; i < chosen.length; i++) {
            if (chosen[i].status == "uploaded") {
                setPage("selectLines");
                lineSelection = true;
                break;
            }
        }

        if (!lineSelection) onSubmit(chosen, s || defaultName);
    }

    const handleSelect = (rows: DocumentType[]) => {
        setChosen(rows);
    }

    const headers = [
        { label: "Files" },
        { label: "Status" }
    ]

    const handleRemoveFromSelected = (docToRemove) => {
        setChosen((previous) => previous.filter((doc) => doc !== docToRemove));
        //setSelected((previous) => previous.filter((doc) => doc !== docToRemove));
    }

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(true);
        };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(false);
        handleFileChange(event);
    };


    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const handleUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSelectLines = async (selected: string[]) => {
        setLoading(true);

        let newChosenIndex = chosenIndex;
        await combineDocuments(cookies['user-id'], chosen[newChosenIndex].instanceId ?? "");
        await updateCoverageLines(cookies['user-id'], chosen[newChosenIndex].instanceId ?? "", selected);
        analyzePolicy(cookies['user-id'], "", chosen[newChosenIndex].instanceId ?? "", "internal", true);

        newChosenIndex += 1;
        while (newChosenIndex < chosen.length && chosen[newChosenIndex].status != "uploaded") {
            newChosenIndex += 1;
        }
        if (newChosenIndex >= chosen.length) {
            onSubmit(chosen, displayName || defaultName);
        } else {
            setChosenIndex(newChosenIndex);
            await new Promise(r => setTimeout(r, 150));
        }

        setLoading(false);
    }
    
    useEffect(() => {
        if (successfulUploads) {
            for (let i = 0; i < successfulUploads.length; i++) {
                const lastUploaded = proposals.find(r => r.instanceId == successfulUploads[i]);
                if (lastUploaded && !chosen.find(s => s.instanceId == successfulUploads[i])) 
                    handleSelect([...chosen, lastUploaded]);
            }
        }
    }, [successfulUploads])
    

    return (
        <CreateProposalContainer>
            { (agencyId != process.env.REACT_APP_PORTAL_AGENCY_ID && agencyId != process.env.REACT_APP_ISU_AGENCY_ID && agencyId != process.env.REACT_APP_LEGACY_AGENCY_ID) && (
                <BlurredBackground>
                    <OverlayText><div style={{ color: "black", fontSize: "30px", fontWeight: "bold" }}>Not available for your agency yet</div></OverlayText>
                </BlurredBackground>
            )}
            { page == "createProposal" && (
                <>
                    <HeaderWrapper>
                        <ProgressDisplay>
                            <Text content="SELECT POLICIES" />
                        </ProgressDisplay>
                        <Button iconLeft={Close} handleClick={handleClose} $borderless/>
                    </HeaderWrapper>
                    <SubHeader>Select Policies for Proposal</SubHeader>
                    <TableContainer
                        isDragging={isDragging}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        $height={ showMyFiles ? "126px" : "246px" }
                        $width="100%"
                        $marginTop="20px"
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "16px", height: "100%", width: "100%"}}>
                            <div style={{ fontSize: "20px", fontWeight: "500" }}>Drag and drop your files</div>
                            <div style={{ fontSize: "16px", fontWeight: "400" }}>or</div>
                            <Button
                                iconLeft={Upload}
                                text="Upload"
                                handleClick={handleUpload}
                            />
                            <HiddenInput
                                type="file"
                                multiple
                                ref={fileInputRef}
                                onChange={(e) => handleFileChange(e)}
                            />
                        </div>
                    </TableContainer>
                    <Space px={16} />
                    <div style={{ display: "flex", flexDirection: "column"}}>
                        <DocumentWizardTable rows={filteredProposals} allRows={proposals} chosen={chosen} onSelect={handleSelect} handleSearch={handleSearch} showMyFiles={showMyFiles} setShowMyFiles={setShowMyFiles}/>
                    </div>

                    {/* TODO Bug here because state is set in child and parent independently, need to make child stateless */}
                    {chosen[0] && 
                        <SelectedDocumentsWrapper>
                            {chosen.map((document, index) => {
                                return(
                                <Button
                                    key={index}
                                    handleClick={() => handleRemoveFromSelected(document)}
                                    iconLeft={FileActive}
                                    text={document.name.length < 60 ? document.name : document.name.slice(0,57) + "..."}
                                    iconRight={CloseActive}
                                    $color={COLORS.UI.BORDER.SELECTED}
                                    $background={COLORS.UI.BACKGROUND.SELECTED}
                                />
                            )})}    
                        </SelectedDocumentsWrapper>
                    }

                    <ButtonWrapper>
                        <Button text="Cancel" handleClick={handleCancel}/>
                        <Button disabled={chosen.length === 0} text="Continue" handleClick={() => setPage("selectName")} $secondary/>
                    </ButtonWrapper>
                </>
            )}
            { page === "selectName" && 
                <SelectName
                    defaultName={displayName || defaultName}
                    onBack={() => setPage("createProposal")}
                    onComplete={handleCreateProposal}
                    handleCancel={handleCancel}
                    documents={chosen}
                    proposal
                />
            }

            { page === "selectLines" && 
                <SelectComparison 
                    onBack={() => setPage("selectName")}
                    policyName={chosen[chosenIndex].name}
                    policyId={chosen[chosenIndex].instanceId}
                    onComplete={handleSelectLines}
                    handleCancel={handleCancel}
                    loading={loading}
                    selected={selected}
                    setSelected={setSelected}
                    handleFileChange={handleFileChange}
                    documents={proposals}
                    singlePolicy
                />
            }
        </CreateProposalContainer>
    );
}

export default CreateProposal;